/**
 * simple drag and drop file uploader for PNP CMS
 * CraigE 29-Aug-2023
 */

// noinspection DuplicatedCode

$(function() {

    const uploadFields = document.querySelector('.fileupload');
    if (uploadFields) {
        uploadFields.addEventListener('change', function (event) {
            let file = event.target.files[0];
            const uploader = $(event.target);
            const id = uploader.attr("id");
            const filename = uploader.val().split("\\").pop();
            $("#wrapper_" + id).text(filename);
            let fileReader = new FileReader();
            fileReader.onload = function () {
                uploader.css({
                    backgroundImage: 'url("' + fileReader.result + '")',
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "100% auto"
                });
                $("#wrapper_" + id).css({
                    backgroundImage: 'url("' + fileReader.result + '")',
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "100% auto"
                });
                uploader.attr("title", filename);
            };
            fileReader.readAsDataURL(file);
        });
    }
});
